import React from "react"
import Layout from "../components/layout"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { Link } from "gatsby"
import frontImage from "../images/undraw_creative_team_r90h.svg"
import ideasImage from "../images/undraw_ideas_s70l.svg"
import tweetImage from "../images/undraw_viral_tweet_gndb.svg"
import mailImage from "../images/undraw_new_message_2gfk.svg"
import PostCard from "../components/post-card"
import { graphql } from "gatsby"

export default ({ data, location }) => {
  const { subtitle, description } = useSiteMetadata()
  const meta = {
    publicationDate: `2020-02-01`,
    lastUpdated: `2020-02-01`,
  }
  return (
    <Layout 
      pathname={location.pathname}
      publicationDate={meta.publicationDate}
      lastUpdated={meta.lastUpdated}>
      <div className="bg-indigo-900 px-4 py-4">
        <div className="px-4 py-10 md:py-4">
          <div className="md:max-w-6xl md:mx-auto">
            <div className="md:flex md:flex-wrap">
              <div className="md:w-1/2 text-center md:text-left md:pt-10">
                <h1 className="text-white mb-4">
                  {subtitle}
                </h1>

                <p className="text-indigo-200 md:text-xl md:pr-48">
                  {description}
                </p>

                <Link
                  to="/"
                  className="hidden mt-6 mb-12 md:mb-0 md:mt-10 inline-block py-3 px-8 text-white bg-red-500 hover:bg-red-600 rounded-lg shadow"
                >
                  Get Started
                </Link>
              </div>
              <div className="w-0 md:w-1/2 md:pt-10 invisible md:visible lg:visible xl:visible">
                <img
                  alt="Collaborative team"
                  className="block mx-auto"
                  src={frontImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120">
          <path
            fill="#3C366B"
            fillOpacity="1"
            d="M0,64L1440,32L1440,0L0,0Z"
          ></path>
        </svg>
      </div>

      <div className="px-12 pt-8 pb-10">
        <div className="md:max-w-6xl md:mx-auto">
          <h3>Read</h3>
          <h2>
            Articles to help you build, launch and develop your business focused on technology products
          </h2>
          <p className="md:text-xl md:p-4">
            Latest posts...
          </p>
          <div className="md:flex md:flex-wrap mb-4">
            {data.latestBlogPosts.edges.map(({ node }) => (
            <div key={node.id} className="sm:w-auto md:w-1/3 p-4">
                <PostCard 
                  title={node.frontmatter.title}
                  excerpt={node.frontmatter.excerpt}
                  slug={node.frontmatter.slug}
                  image={node.frontmatter.featuredImage.childImageSharp.fluid}>
                </PostCard>
            </div>
            ))}
          </div>
          <div className="px-4">
            <Link to="/blog/"
              className="mt-6 inline-block py-3 px-8 text-white bg-indigo-900 hover:bg-indigo-600 rounded-lg shadow"
            >
              See all posts
            </Link>
          </div>
        </div>
      </div>

      <div className="px-12 py-10 bg-gray-100">
          <div className="md:max-w-6xl md:mx-auto">
            <h3>Listen</h3>
            <h2>
              Conversations on product-related topics and reflections on individual technology product journeys
            </h2>
            <p className="md:text-xl md:p-4">
            Latest podcast episodes...
          </p>
          <div className="md:flex md:flex-wrap mb-4">
            {data.latestPodcastEpisodes.edges.map(({ node }) => (
            <div key={node.id} className="w-full md:w-1/3 p-4">
                <PostCard 
                  title={node.frontmatter.title}
                  excerpt={node.frontmatter.excerpt}
                  slug={node.frontmatter.slug}>
                </PostCard>
            </div>
            ))}
          </div>
          <div className="px-4">
            <Link to="/podcast/"
              className="mt-6 inline-block py-3 px-8 text-white bg-indigo-900 hover:bg-indigo-600 rounded-lg shadow"
            >
              See all episodes
            </Link>
          </div>
          </div>
      </div>

      <div id="connect" className="px-12 py-10 bg-white">
        <div className="md:max-w-6xl md:mx-auto">
          <h3>Connect</h3>
          <h2>
            Join in With the Speaking of Products Community
          </h2>

          <div className="p-4">
            <div className="md:max-w-6xl md:mx-auto">
                  <div className="md:flex md:flex-wrap md:items-center md:pb-10">
                    <div className="md:w-1/2 text-center md:text-left">
                      <p className="md:text-xl md:pr-4">
                        Share your questions and thoughts with the podcast.
                      </p>
                      <Link
                        to="/contact/"
                        className="mt-6 mb-12 md:mb-0 md:mt-10 inline-block py-3 px-8 text-white bg-indigo-900 hover:bg-indigo-600 rounded-lg shadow"
                      >
                        Contact
                      </Link>
                    </div>
                    <div className="w-0 md:w-1/3 md:pt-10 invisible md:visible lg:visible xl:visible">
                      <img
                        alt="Questions and thoughts"
                        className="block mx-auto"
                        src={ideasImage}
                      />
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap md:items-center md:py-10">
                    <div className="w-0 md:w-1/2 md:pr-4">
                      <img
                        alt="Question board"
                        className="block mx-auto"
                        src={tweetImage}
                      />
                    </div>
                    <div className="md:w-1/2 text-center md:text-left md:pt-10">
                      <h4 className="md:text-xl pb-2">
                        Be part of the conversation with us on Twitter.
                      </h4>
                      <a
                        href="https://twitter.com/SpeakOfProducts"
                        className="mt-6 mb-12 md:mb-0 md:mt-10 inline-block py-3 px-8 text-white bg-indigo-900 hover:bg-indigo-600 rounded-lg shadow"
                      >
                        Follow
                      </a>
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap md:items-center md:py-10">
                    <div className="md:w-1/2 text-center md:text-left">
                      <h4 className="md:text-xl md:pr-4">
                        Subscribe to our newsletter.
                      </h4>
                      <div
                        className="mt-6 mb-12 md:mb-0 md:mt-10 inline-block py-3 px-8 text-white bg-indigo-900 hover:bg-indigo-600 rounded-lg shadow"
                      >
                        (coming soon...)
                      </div>
                    </div>
                    <div className="w-0 md:w-1/2 invisible md:visible lg:visible xl:visible">
                      <img
                        alt="Collaborative team"
                        className="block mx-auto"
                        src={mailImage}
                      />
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </Layout>
  )
}
export const query = graphql `
query{
  latestBlogPosts: allMdx(
    sort: {fields: frontmatter___lastUpdated, order: DESC}, 
    limit: 3, 
    filter: {frontmatter: {status: {eq: "published"}, type: {eq: "blog-post"}}}) {
    edges {
      node {
        id
        frontmatter {
          title
          slug
          lastUpdated
          excerpt
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 530, maxHeight: 530, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
  latestPodcastEpisodes: allMdx(
    sort: {fields: frontmatter___lastUpdated, order: DESC}, 
    limit: 3, 
    filter: {frontmatter: {status: {eq: "published"}, type: {eq: "podcast-episode"}}}) {
    edges {
      node {
        id
        frontmatter {
          title
          slug
          lastUpdated
          excerpt
        }
      }
    }
  }
}
`